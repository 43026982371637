.site-footer {
	--_max-width: var(--wrapper-width, 1380px);
	--_spacing-xl: var(--wp--preset--spacing--xl, 3rem);
	--_spacing-xs: var(--wp--preset--spacing--xs, 1.5rem);
	--_spacing-s: var(--wp--preset--spacing--s, 1rem);
	--_spacing-m: var(--wp--preset--spacing--m, 1.25rem);
	--_color-primary: var(--wp--preset--color--primary, #ffe53e);
	--_color-contrast: var(--wp--preset--color--contrast, #172b02);
	--_color-hover: var(--wp--preset--color--green-mid-2, #83AC0C);
	--_gap: var(--_spacing-xl);
	--_caret: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' fill='none'%3e%3cpath stroke='%23172B02' stroke-width='1.5' d='m1 1.57 6 5 6-5'/%3e%3c/svg%3e");
	background: var(--_color-primary);
	padding: var(--_spacing-xl) var(--_spacing-xs);
}

.site-footer__container {
	margin: 0 auto;
	max-width: var(--_max-width);
	padding: 0 var(--_spacing-m);
}

.site-footer__menus {
	padding-bottom: var(--_spacing-xl);

	@media (min-width: 1100px) {
		border-bottom: 1px solid var(--_color-contrast);
		background-image: url(../images/hometree-logomark.svg);
		background-position: bottom right;
		background-repeat: no-repeat;
	}
}

.site-footer__title {
	margin-bottom: var(--_spacing-xl);
}

.site-footer__title svg {
	display: block;
	color: var(--_color-contrast);
	width: 136px;
	height: auto;

	@media (min-width: 1100px) {
		margin: 0 auto;
		width: 242px;
	}
}

.site-footer__columns {

	@media (min-width: 1100px) {
		columns: 4;
		gap: var(--_gap);
	}
}

.footer-section {
	break-inside: avoid;
	// break-after: column;
	margin-bottom: var(--_spacing-m);

	@media (min-width: 1100px) {
		margin-bottom: 28px;
	}
}

.footer-section:nth-child(2) {
	// break-after: avoid-column;
}

.footer-section:nth-child(3) {
	// Remove bottom margin in desktop to allow positioning
	// of the social icons.
	@media (min-width: 1100px) {
		// margin-bottom: 0;
	}
}

.footer-section--legal {
	break-before: column;
}

.footer-section__title {
	// ATM we are using .has-h-5-font-size,
	// for the footer to be used in the external site
	// we need to copy any styles settings coming from the theme
	@media (min-width: 1100px) {
		margin-bottom: 8px;
	}
}

.footer-section__title button {
	appearance: none;
	background: inherit;
	color: inherit;
	font-size: inherit;
	border: none;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	text-align: left;
	font-weight: inherit;
	font-family: inherit;
}

.footer-section__title button:after {
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	background: var(--_color-contrast);
	-webkit-mask: var(--_caret) no-repeat center / 1rem;
	mask: var(--_caret) no-repeat center / 1rem;

	// Do now show in desktop.
	@media (min-width: 1100px) {
		display: none;
	}

	@media (prefers-reduced-motion: no-preference) {
		transition: 0.3s ease-in-out transform;
	}
}

.footer-section__title button[aria-expanded="true"]:after {
	transform: rotate(180deg);
}

.footer-section [data-footerdion-panel] {
	display: grid;
	grid-template-rows: 0fr;

	& > * {
		overflow: hidden;
	}

	// Always open in desktop
	@media (min-width: 1100px) {
		display: block;
	}

	@media (prefers-reduced-motion: no-preference) {
		transition: 0.3s ease-in-out grid-template-rows;
	}
}

.footer-section [data-footerdion-panel][aria-hidden="false"] {
	grid-template-rows: 1fr;
}

.footer-section__list a {
	color: var(--_color-contrast);
}

.footer-section li {
	margin: var(--_spacing-s) 0;

	@media (min-width: 1100px) {
		margin: 6px 0;
	}
}

.footer-section li:last-child {
	margin-bottom: 0;
}

.site-footer__social {
	margin-top: var(--_spacing-xl);

	@media (min-width: 1100px) {
		// margin-top: -1.5rem;
		margin-top: var(--_spacing-s);
		margin-bottom: -24px;
	}

	ul {
		display: flex;
		// Behold! gap is not working to separate
		// the list items in older Safari/iOS,
		// we rely on margin-right instead.
		// Apparently this has something to do with the
		// usage of SVG symbols.
	}

	li {
		margin-right: 0.75rem;
	}

	li:last-child {
		margin-right: 0;
	}

	.icon {
		width: 1.5rem;
		height: 1.5rem;
		fill: var(--_color-contrast);
		transition: 0.3s ease-in-out fill;
	}

	a:is(:hover, :focus) {
		.icon {
			fill: var(--_color-hover);
		}
	}
}

.footer-colophon {
	@media (min-width: 1100px) {
		padding-top: var(--_spacing-xl);
	}
}
